<template>
  <div class="store-card-list-component">
    <div class="list">
      <div class="item" :key="index" v-for="(item, index) in data">
        <StoreCard :data="item" />
      </div>
    </div>
  </div>
</template>

<script>
import StoreCard from './module/storeCard'

export default {
  components: {
    StoreCard
  },

  props: {
    data: {
      type: Array,
      require: true
    }
  }
}
</script>

<style lang="scss" scoped>
.store-card-list-component {
  .list {
    .item {
      display: inline-block;
      margin-right: 15px;
      margin-bottom: 15px;
      width: 190px;
      height: 180px;
      background: #ffffff;
      border: 1px solid #e8e8e8;
      border-radius: 4px;
    }
  }
}
</style>